nav {
  margin-bottom: 20px;
}

.nav-item {
  padding-top: 6px;
  margin: 0 4rem;
  border-bottom: 4px solid black;
}

.item-active {
  border-bottom: 4px solid #d81b60;
}

.containerLogoKanguroo {
  width: 4.375rem;
  height: 3.125rem;
  position: fixed;
  left: 0.625rem;
  top: 0.625rem;
  margin-left: 0.3rem;
}

.headerLogoKanguroo {
  max-height: 2.5rem;
  width: auto;
}

