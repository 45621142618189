td.center-table-item,
th.center-table-item {
  text-align: center;
  vertical-align: middle;
}

.nameColumn {
  width: 15%;
}

.modal-file-body {
  margin: 10px;
}

#_space {
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
}

.div-space {
  width: 150px;
  height: 75px;
  background-color: #eeeeee;
  border-radius: 5px;
}

.div-button-add-file {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
}

.button-add-file {
  width: 35px;
  height: 35px;
  border-color: transparent;
}

.metric-upload-file {
}

.label-title {
  font-size: 14px;
  font-weight: 500;
}

.label-subtitle {
  font-size: 11px;
  color: #8a8b8d;
}

.metric-upload-file-title {
  font-weight: bold;
  font-size: 11px;
  color: #999999;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
}

.metric-upload-file-label {
  font-weight: bold;
  font-size: 26px;
  color: #222428;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
}

.footer-modal-file {
  border-top: 1px solid black !important;
  margin: 20px;
  margin-top: 0px;
}

.div-btn-footer {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.custom-modal-width {
  /* height: 200px; */
}

.delete-modal-btn {
  background-color: #d81b60;
  color: white;
}

.modalUploadFile {
  /* width: 90vw ; 
  max-width: 90vw; */
}

.div-modal-add-file {
  background-color: #222428;
  flex-wrap: nowrap !important;
}

.loaderUploadFile {
  height: 55;
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translate(-50%, -50%);
}

.loaderUploadFile.guest {
  height: 55;
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
}

.labelInputFileUpload {
  color: #fff !important;
  font-size: 13px !important;
}

.input-add-modal-file {
  border: 0px;
  border-bottom: 1px solid #fff !important;
}

.input-add-modal-file.form-control {
  color: #fff !important;
  font-size: 13px;
}

.MuiInputLabel-root.labelFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.selectFileUpload::before {
  border-bottom: 1px solid #fff !important;
}

.selectFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.formSelectUpload > div .MuiSelect-icon {
  color: #fff !important;
}

.Mui-focused.labelFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.selectFileUpload.Mui-focused::after {
  outline: white;
  box-shadow: 0 1px 0 0 #fff;
  border-bottom: 1px solid #fff !important;
}

.centerBtn {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.btnAddFile {
  height: 30px;
}

.btnAbortAddFile {
  height: 30px;
}

.grey {
  background-color: #9c9c9c;
}

button.btn.grey:hover,
button.btn.grey:active,
button.btn.grey:focus {
  background-color: #9c9c9c !important;
  transition: all 250ms;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.invoice-modal-dowload-button {
  cursor: pointer;
}

.invoice-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invoice-modal-dowloads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.invoice-modal-dowload-item {
  margin-bottom: 1rem;
}
