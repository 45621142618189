.delegataire-editor-form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.delegataire-dates-container {
  display: flex;
  gap: 4rem;
}

.delegataire-dates {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bold-titles {
  font-size: 1rem;
  font-weight: 500;
}

.delegataire-quota-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
}

.delegataire-quota-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  gap: 0.625rem;
}

.delegataire-quota-section .cmp-quota-title-container {
  display: flex;
  gap: 1rem;
}

.delegataire-quota-row {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  justify-content: space-between;
}

.row-title h4 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0px;
}

.delegataire-quota-row .delete-btn {
}

.quota-rows-container {
  /* display: flex;
  flex-direction: column;
  gap: 1.5rem; */
  margin-bottom: 1rem;
  width: fit-content;
}

.new-row-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-row-section button {
  width: fit-content;
}

.new-row-section .row-select-input {
  width: fit-content;
}

.quota-nbr-and-rmv-btn {
  display: flex;
  gap: 0.625rem;
}

.delegataire-table-head .tab-cell {
  white-space: nowrap;
}

.cell-componant-quota {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.cell-componant-quota,
.cell-category {
  margin-bottom: 0.5rem;
}

.cell-componant-quota:last-child,
.cell-category:last-child {
  margin-bottom: 0rem;
}

.cell-delegataire-packages {
  margin: 0px;
}

.delegataire-table-cell-name {
  margin: 0;
}

.delegataire-table-filter {
  display: flex;
  /* gap: 1rem; */
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.del-filter-items {
  width: fit-content;
  margin-right: 1rem;
}

.del-filter-inputs {
  width: 100%;
  max-width: 350px;
}
