.list_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .list_form {
  min-width: 870px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2rem;
} */

.list-main-content {
  background-color: #e1dfdf;
  border-radius: 0.5rem;
  padding: 1.2rem;
  margin: 0 0 1rem 0;
}

.list_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
}

.list_header .list_name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
}

.list_name h4 {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
}

.list_name .helperText {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.75em;
  text-align: center;
  position: absolute;
}

.list-dialog-close-btn {
  color: #000000;
}
