.event-header {
  background-color: #232428;
  padding: 7px;
  color: white;
}

.delegataire-event-name {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.delegataire-event-dates {
  font-size: 13px;
  font-weight: normal;
}

.delegataire-contact {
  padding-top: 15px;
}

.emailPreview h2 {
  font-size: 16px;
  font-weight: bold;
}

.emailPreview b {
  font-weight: bold;
}

.emailPreview-container {
  max-width: 600px;
  margin: auto;
}

.emailPreviewModal .modal-lg {
  max-width: 640px !important;
}

.metric-panel {
  min-width: 180px;
}

.stats-and-actions-item.col-2 {
  padding-left: 0px;
}

.stats-and-actions-item .metrics.row {
  margin-left: 0px;
  margin-right: 0px;
}

.my-packages {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}

.button-right {
  justify-content: right;
  display: flex;
  flex: 1;
}

.modal-delegataire > .modal {
  overflow-y: auto;
}

.blue-background {
  background-color: rgb(179, 233, 233); 
}

.yellow-background {
  background-color: rgb(255, 247, 154); 
}


