a.link-resetpassword {
  font-size: 12px;
}
a.link-resetpassword:hover,
a.link-resetpassword:focus,
a.link-resetpassword:active {
  color: #d81b60 !important;
}

.forgot-pwd-link-container {
  margin-bottom: 1rem;
}

.forgot-pwd-link {
  color: #c21b2f;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.forgot-pwd-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #d81b60;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.forgot-pwd-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

input.form-control.validate.logout-pwd-field {
  margin-bottom: 1rem;
}
