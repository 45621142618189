#MenuEditor .draggable-item {
  height: 200px !important;
}

#MenuEditor .form-group {
  line-height: 20px;
}
.select-component{
  border-bottom: 1px solid #fff !important;
}
