.container {
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.gabarit1-page-container {
  /* max-width: 100%; */
  padding-inline: 0px;
}

.gabarit1-page-container .row {
  margin-right: 0px;
  margin-left: 0px;
  padding-inline: 0px;
}

.kanguroo-page {
  padding-bottom: 100px;
}

.page-navbar {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  width: "100%";
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin: auto;
  }
}

#checkouform .modal-dialog .modal-content {
  border: 0px;
  box-shadow: none;
}

.background {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

strong {
  font-weight: bold !important;
}

.hidden {
  display: none !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:after {
  background-color: grey !important;
}

.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:before,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:before,
label.btn input[type="radio"].with-gap:checked + label:after {
  border: 2px solid grey !important;
}

.form-check-input[type="radio"]:not(:checked) + label,
.form-check-input[type="radio"]:checked + label,
label.btn input[type="radio"]:not(:checked) + label,
label.btn input[type="radio"]:checked + label {
  line-height: 1.4625rem;
  padding-left: 25px !important;
}

.form-check-label {
  height: auto !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label:after,
label.btn input[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid grey !important;
  background-color: grey !important;
  z-index: 0;
}

.form-inline > .form-check {
  margin-top: 0px !important;
}

.form-element {
  margin-bottom: 0px !important;
}

.guests-container {
  background-color: #eeeeee;
}

.table-title {
  color: black;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid grey;
}

.error {
  color: red;
  font-weight: bold;
  display: none;
}

.required .error {
  display: inline;
}

.img-header {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-container .form-group {
  margin-bottom: 5px !important;
}

.page-sections img {
  max-width: 100% !important;
  width: auto;
  margin: auto;
}

.calendar-item img {
  width: 20px;
  height: 20px;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}

.side-container .form-group > .form-group {
  padding-top: 1px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.modal-calendar .modal-header .close {
  margin: -1rem -4rem -1rem auto !important;
  opacity: 1;
  font-size: 35px;
  color: white;
}

.fc-event {
  border: 1px solid white !important;
}

.fc-head,
.fc-day-grid {
  display: none;
}

#calendar-modal-icon {
  position: absolute;
  width: 30px;
  right: 20px;
}

.calendar-day {
  border-bottom: #7a7476 2px solid;
  height: 2rem;
}

.item-calendar-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.haveIcon .item-calendar-title {
  padding-right: 30px;
}

.fc-widget-content {
  border: 0px !important;
}

.fc-axis {
  border: 0px !important;
  background-color: white !important;
}

.fc-axis span {
  font-size: 1rem;
  color: #808080;
}

.fc-divider {
  display: none;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.calendar-content {
  overflow-x: auto;
}

.fc-slats td {
  height: 50px !important;
  font-size: 8px !important;
}

.calendar-content .fc.fc-ltr.fc-unthemed {
  min-width: 330px;
}

.fc-view-container {
  width: auto;
  min-width: fit-content;
}

@media (max-width: 767px) {
  table {
    border-collapse: collapse;
    height: inherit !important;
    overflow-x: auto;
    max-width: 100%;
    margin: auto;
  }

  .calendar-item {
    width: auto;
  }

  .page-sections span img {
    max-width: none !important;
    width: auto;
    margin: auto;
  }

  .page-sections p > img {
    height: auto !important;
  }

  .col-md-12 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .page-sections .row {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.navbar-toggler-button {
  visibility: visible;
}

@media (min-width: 768px) {
  .navbar-toggler-button {
    visibility: hidden;
  }
}
