.quota-state {
  color: #ffffff;
}

.quota-state-empty {
  background-color: #f79f33;
}

.quota-state-full {
  background-color: #b3e9e9;
}

.quota-state-partial {
  background-color: #e6e612;
}

.general-actions {
  display: flex;
  gap: 2rem;
}
