body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  overscroll-behavior: none;
}

.App nav ~ div.row {
  /* display: flex; */
  margin-right: auto;
}

.centerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.centerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
  height: 100%;
}

.columnFlex {
  display: flex;

  flex-direction: column;
  height: 100%;
}

.rowFlex {
  display: flex;

  flex-direction: row;
  height: 100%;
}

.subs {
  align-items: center;
  display: flex;
  margin-left: 50px;
}

.displayFlex {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1050px !important;
  }
}

* {
  -webkit-overflow-scrolling: touch;
}

.pagination .page-item.active .page-link,
.page-item.active:hover .page-link:hover {
  background-color: #d81b60;
}

.app-pink {
  color: #d81b60;
}

.app-dark {
  color: #373b44;
}

html,
body,
#root,
.App {
  height: 100%;
}

table.table thead th {
  font-weight: bold;
}

.form-control {
  font-size: 15px;
}

.rdw-editor-wrapper {
  border: 1px solid grey;
}

.custom-select {
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  background-color: transparent;
}

.form-row {
  padding: 15px;
  background-color: white;
}

.form-item-desc {
  font-size: 12;
  font-style: italic;
}

.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]),
input:focus,
.active-custom-nav {
  outline: white;
  box-shadow: 0 1px 0 0 rgb(220, 32, 119);
  border-bottom: 1px solid rgb(220, 32, 119);
}

nav {
  margin-bottom: 0px;
}

.breadcrumb {
  background-color: white;
  color: black;
  font-weight: 500;
  margin-top: 55px;
}

.custom-nav {
  color: black;
  font-weight: 500;
}

.custom-nav a {
  line-height: 40px;
  text-align: center;
  color: black;
}

.side-nav .collapsible a.active,
.side-nav .collapsible a:hover {
  font-weight: bold;
}

.collapsible-body a.active,
.collapsible-body a:hover {
  border-left: rgb(220, 32, 119) 2px solid !important;
  font-weight: bold;
}

.table-container {
  padding: 5px;
}

table {
  background-color: white;
}

table th {
  font-weight: 500;
}

table.table-sm th {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

table.table-sm td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  word-break: break-word;
}

.table-container {
  padding: 10px;
  background-color: white;
}

.table-container [type="checkbox"]:not(:checked),
.table-container [type="checkbox"]:checked {
  margin-top: 5px;
}

.nameColumn-email {
  font-size: 10px;
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.main-content {
  margin-top: 60px;
  padding-bottom: 50px;
}

.component-table .table-container {
}

.component-table table thead tr th:nth-child(1) {
  width: 30px;
}

.component-table table thead tr th:nth-child(2) {
  width: 200px;
}

.component-table table thead tr th:nth-child(3) {
  width: 100px;
}

.component-table table thead tr th:nth-child(4) {
  width: 80px;
}

.component-table table thead tr th:nth-child(5) {
  width: 30px;
}

.component-table table thead tr th:nth-child(6),
.component-table table thead tr th:nth-child(7) {
  width: 80px;
}

.component-table table thead tr th:nth-child(8) {
  width: 120px;
}

.component-table table thead tr th:nth-child(9) {
  width: 60px;
}

.account-sub-title {
  margin-top: 56px;
  height: 45px;
  background-color: white;
  padding-top: 10px;
}

.calendar-event-detail {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: white;
  background-color: #414141;
  padding: 4px 8px;
  cursor: pointer;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.calendar-event-detail:hover {
  padding: 4px 12px;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.calendar-icon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.calendar-spaces {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

.calendar-item {
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.calendar-item:hover {
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.fc-event {
  background-color: white !important;
  border: 1px solid white !important;
}

#svg-container div[role="toolbar"] button:last-of-type,
div[role="navigation"] {
  display: none !important;
}

.cancel-button {
  background-color: #373b44 !important;
  color: white;
}

.cancel-button:hover,
.cancel-button:active,
.cancel-button:focus {
  color: #ffffff;
  background-color: #515764;
}
.draft-button {
  background-color: #989898 !important;
  color: white;
}

.draft-button:hover,
.draft-button:active,
.draft-button:focus {
  color: #ffffff;
  background-color: #989898;
}

button.btn.pink:hover,
button.btn.pink:active,
button.btn.pink:focus {
  background-color: #a11448 !important;
  transition: all 250ms;
}

button.btn.dark:hover,
button.btn.dark:active,
button.btn.dark:focus {
  background-color: #2a2b2c !important;
  transition: all 250ms;
}

button.btnDark {
  background-color: #2a2b2c !important;
}

.preview-container {
  margin: auto;
}

.preview-container img {
  width: auto;
}

.light-grey {
  background-color: #f8f8f8;
}

.modal-inner {
  padding: 15px;
}

.modal-full-screen .modal-dialog {
  width: 100% !important;
  margin: 0 !important;
  padding: 0;
  min-width: -webkit-fill-available;
}

.modal-full-screen .modal-body {
  margin: 0;
  padding: 0;
}

.row-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.metric-panel-guest {
  margin: 0.375rem 0.375rem 0.375rem 0;
  color: black;
  font-size: 13px;
  text-align: right;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px 8px 3px 8px;
  width: 120px !important;
  /* display: inline-block; */
}
.metric-panel-participant {
  margin: 0.375rem;
  color: black;
  font-size: 13px;
  text-align: right;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px 8px 3px 8px;
  width: 160px !important;
  /* display: inline-block; */
}

.stats-panel {
  margin: 0.475rem;
  color: black;
  font-size: 13px;
  text-align: right;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px;
  /* display: inline-block; */
}

#_composants {
  width: 120px;
}

#_invite_total {
  width: 140px;
}

#_participant_total {
  width: 180px;
}

#_stats_total {
  width: 230px;
}

.sortable-title {
  padding-right: 10px !important;
}

.sortable-title-label {
  /* width: 85%; */
  float: left;
  min-height: 30px;
  padding-left: 15px;
  padding-right: 5px;
}

.sortable-title-arrow {
  width: 10px;
  float: right;
  position: relative;
  /*min-height:30px;
  */
  /*padding-right: 5px;
  */
  display: none;
}

.sortable-title:hover .sortable-title-arrow {
  display: block;
}

.pointer-asc {
  position: absolute;
  bottom: 20px;
}

.pointer-desc {
  position: absolute;
  bottom: 0;
}

.pointer-asc,
.pointer-desc {
  opacity: 1;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.pointer-asc:hover,
.pointer-desc:hover {
  opacity: 0.6;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms;
}

.metric-panel-title {
  font-weight: bold;
  font-size: 11px;
  color: #999999;
  text-align: right;
  padding: 1px;
}

.metric-panel-value {
  font-weight: bold;
  font-size: 28px;
  color: #222428;
  text-align: right;
  padding-right: 0px;
}

.stats-panel-title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.stats-panel-label {
  font-weight: bold;
  font-size: 12px;
  color: #999999;
  text-align: left;
}

.stats-panel-value {
  font-weight: bold;
  font-size: 28px;
  color: #222428;
  text-align: right;
}

.metric-panel-label {
  font-weight: bold;
  font-size: 20px;
  color: #222428;
  text-align: center;
  background-color: white;
  padding: 10px;
}
.metric-panel {
  margin: 0.375rem 0.375rem 0.375rem 0;
  color: black;
  font-size: 13px;
  text-align: right;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 8px;
  /* display: inline-block; */
}

.metric-panel span {
  /*font-size: 30px;*/
}

.campaign-panel-title {
  font-weight: bold;
  text-align: left;
}

.campaign-panel-label {
  font-weight: bold;
  font-size: 14px;
  color: #818181;
  text-align: left;
}

.campaign-panel-label-date {
  font-weight: bold;
  font-size: 12px;
  color: #999999;
  text-align: left;
}

.general-actions {
  text-align: right;
}

.general-actions button,
.general-actions a {
  font-weight: bold;
}

.general-actions .button-container {
  max-width: 200px;
  display: inline-block;
}

.filters {
  padding: 15px;
  margin-bottom: 15px;
}

.margin-top-60 {
  margin-top: 60px;
}

.actions-container-fixed {
  position: fixed;
  bottom: 0;
}

.actions-container {
  background-color: #383b44 !important;
  padding: 13px;
  left: 0;
  width: 100%;
  z-index: 1050;
}

.actions-container .custom-select {
  color: white;
  background-color: #232428;
}

.actions-container .custom-select.email-template {
  color: inherit !important;
  background-color: transparent !important;
}

.actions-container .form-group > input,
.actions-container .form-group > label,
.actions-container .form-group > .md-form > input,
.actions-container .form-group > .md-form > label {
  color: white !important;
}

.payment-method-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0px;
  /* gap: 0rem; */
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: auto; */
}

.payment-method-container label {
  margin: 0 !important;
}

.payment-method-container .payment-mode-icons {
  width: auto;
}

.payment-method-container .payment-mode-card {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin-right: 3rem;
}

@media (width <= 425px) {
  .payment-method-container .payment-mode-card {
    margin-right: 0rem;
  }
}

.payment-mode-card .payment-mode-icons img {
  margin: 0px 0.375rem;
}

.payment-method-container .payment-mode-card .form-check {
  padding-left: 0px;
}

.payment-method-container .check-payment-mode-input {
  display: flex;
  gap: 0.5rem;
}

.payment-method-container .check-payment-mode-input .form-check {
  padding-left: 0px;
}

.stripe-field {
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-bottom: 1px solid grey;
}

.stripe-field label {
  font-size: 12px;
}

.stripe-field .is-empty {
  color: #eee !important;
}

.tox-selectfield select {
  display: block !important;
}

.toast {
  color: white !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.toasts-container {
  width: 320px !important;
  border-radius: 0px !important;
}

.toast-success {
  background-color: #07bc0c !important;
}

.toast-error {
  background-color: #ff3547 !important;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.row-checkbox-pj [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: initial !important;
  opacity: 0;
  pointer-events: none;
}

.template-checkbox:checked {
  position: absolute !important;
}

.checkbox-pj {
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.error-input {
  border-color: red;
}
.container_guest_buttons {
  text-align: end;
  padding-top: 10px;
}
.container-btn-creating-guest {
  display: flex;
  justify-content: flex-end;
}
.container_guest_buttons .button-container {
  max-width: 200px;
  display: inline-block;
}

.container_guest_buttons button {
  font-weight: normal;
}

.iconDisabled {
  opacity: 0.5;
}

.delegataire-date-title {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

.delegataire-date-text {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.user-formpage-category-input {
  position: relative;
  z-index: 2;
}
