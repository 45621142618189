#myTab {
  /* margin-left: 150px; */
  margin-top: 20px;
  border-bottom: none !important;
}

.nav-item-active {
  border-bottom: 4px solid #D81A60;
}

.nav-item-active>button {
  color: white !important;
}

.nav-item-active>.nav-link.active {
  color: black !important;
}

.simple {
  border-bottom: none !important;
}

.flex {
  display: flex;
  flex: 1
}

.flex-row {
  flex-direction: row;
}

.center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.rowTitle {
  margin-top: 20px
}

.marginTop {
  margin-top: 20px !important;
}

.titlePageImport {
  font-weight: bold !important;
  color: #D81A60
}

.titlePageImport2 {
  font-weight: bold !important;
  color: #003142
}

.noteImport {
  font-style: italic;
  font-size: 10px;
}

.thConfigImportTableHead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  text-align: center;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 70px;
}

.thConfigImportTableSelect {
  text-align: center;
  font-size: 12px !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 70px;
}

.thConfigImportTable {
  text-align: center;
  font-size: 12px !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 100px;
  overflow-wrap: break-word;
}

.labelCell {
  width: 90%;
}

.selectImport {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.selectImport fieldset {
  height: 100%;
  top: auto;
  background-color: inherit;
}

.selectImport legend {
  margin-left: 10em !important;
  height: 0px !important;
  padding: initial !important;
}

.selectImportNumbers {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  /* color: #fff !important; */
  font-size: 12px !important;
}

.selectImportNumbers fieldset {
  height: 100%;
  top: auto;
  background-color: inherit;
}

.selectImportNumbers legend {
  margin-left: 10em !important;
  height: 0px !important;
  padding: initial !important;
}

#selectImportActif {
  background-color: #D81A60 !important;
  color: #fff !important;
  border-radius: 12px !important;
}

#selectImportEmptyWhite {
  border-radius: 12px !important;
}

.selectItemEmpty {
  font-size: 12px !important;
}

.selectItem {
  font-size: 12px !important;

}

.dragLoader {
  width: 100%;
  color: #D81A60;
}

.dragdropFile {
  background-color: #fff;
  border-color: #CBCBCB !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  justify-content: center !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragdropFile label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragdropFileError {
  background-color: #fff;
  border-color: red !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  justify-content: center !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragdropFileError label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragDropFileLoader {
  background-color: #fff;
  border-color: #CBCBCB !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  padding: 8px 0px 8px 0px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragDropFileLoader label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragDropLabelEmpty {
  font-size: 13px;
}

.moveDiv {
  z-index: 1;
}

.pushRight {
  margin-left: auto;
}

.moveComponentLeft {
  position: relative;
}

.moveComponentRight {
  position: relative;
}

.moveButton {
  width: 120px !important;
  color: #ffff !important;
  background-color: #D81A60 !important;
  margin: 10px !important;
  padding: 20px !important;
  height: 40px !important;
  border-radius: 20px !important;
  cursor: pointer !important;
}