@media (min-width: 800px) {
  .side-nav.fixed {
    transform: translateX(0) !important;
  }
}

.side-nav-eventcontainer {
  text-align: center;
  position: relative;
  padding: 15px;
  color: black;
}

.side-nav-eventname {
  margin: auto;
  color: white;
  margin-top: 10px;

}

.side-nav-eventlogo {
  background-color: white;
  margin: auto;
  padding: 10px;
  width: 80%;

}

.label-template-emails {
  width: 450px;
}

.margin-left {
  margin-left: 10px;
}

.div-margin-left {
  margin-left: 10px;
}