.draggable-item,
.draggable-item-horizontal {
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.draggable-item {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 2px;
}

.reponse-container {
  height: 800px;
}

.zone {
  padding: 10px;
  border: 4px #ccc dashed;
  margin-bottom: 5px;
  margin-right: 5px;
}

.column-drag-handle {
  cursor: move;
}

.smooth-dnd-container {
  height: 100%;
}

.components-list {
  height: 300px;
  overflow: scroll;
}
.openModal{
  cursor: pointer;
}
