.checkin-app {
  /*  height: 100%; */
}

.checkin-app .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.checkin-app .app-container {
  margin-top: 50px;
  /* height: 100%; */
}

.checkin-app .app-container-mobile {
  /*  height: 100%; */
}

.checkin-app .statistics {
  background-color: white;
  border-radius: 20px;
  margin-top: 0px;
}

.checkin-app .modal-dialog {
  /*
  margin-top: 100px; 
  top: 50%;
  margin-top: -50% !important;
  */
}

.checkin-app .modal-full-screen .modal-dialog {
  top: 0 !important;
  margin-top: 0 !important;
}

.checkin-app .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.checkin-filter {
  width: 100%;
  position: fixed;
  top: 50px;
  background-color: #eeeeee;
  z-index: 2;
}

.checkin-filter .md-form {
  margin-top: 9px;
}

.checkin-filter .md-form label.active {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.checkin-filter .input-search {
  padding-bottom: 4px;
}

.checkin-list {
  background-color: white;
  margin-top: 150px;
  padding-bottom: 68px;
}

.checkin-list .checkin-list-item {
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid grey;
  line-height: 15px;
}

.checkin-list-item-guestcompany {
  text-transform: uppercase;
  font-size: 14px;
}

.checkin-list-item-guestname {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.checkin-list-item-guestfirstname {
  text-transform: uppercase;
}

.checkin-list-item-guestfirstname {
  text-transform: capitalize;
}

.checkin-list-item-checkindata {
  text-align: right;
}

.button-addguest {
  border-radius: 0;
}

.checkin-app button.purple-gradient {
  background: rgb(214, 43, 90) !important;
  background: -moz-linear-gradient(90deg, rgba(214, 43, 90, 1) 0%, rgba(143, 42, 86, 1) 100%) !important;
  background: -webkit-linear-gradient(90deg, rgba(214, 43, 90, 1) 0%, rgba(143, 42, 86, 1) 100%) !important;
  background: linear-gradient(90deg, rgba(214, 43, 90, 1) 0%, rgba(143, 42, 86, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d62b5a", endColorstr="#8f2a56", GradientType=1) !important;
  font-weight: bold;
}

.checkin-app button.btn-bg-gradient-negatif {
  background: #fff !important;
  background-color: #fff !important;
  border: solid 1px rgba(214, 43, 90, 1) !important;
  color: rgba(214, 43, 90, 1);
  font-weight: bold;
}

.checkin-app button.btn-bg-darkgrey {
  background: #454545 !important;
  background-color: #454545 !important;
  margin-top: 50px;
}

.modal-full-screen .modal-content {
  background-color: transparent;
}

.form-checkin-add-guest .md-form {
  margin-bottom: 0;
}

.img-logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.row-plan {
  flex-wrap: inherit !important;
}

.col-child {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.col-child>.col-md-3 {
  max-width: none !important;
}

.arrow-center {
  margin-left: 50px;
  margin-top: 12px;
}

.placement-guest-infos {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.placement-map svg {
  width: 100%;
  height: calc(100vh - 270px);
}

@media(min-width: 641px) {
  .col-child {
    margin-left: 18px;
  }
}

@media (max-width: 640px) {

  .form-group {
    min-width: 210px;
    margin-top: 10px !important;
  }

  .row-plan {
    display: inherit;
    flex-wrap: inherit;
  }

  .col-child {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .child {
    min-width: inherit !important;
  }

  .font-weight-bold {
    font-size: 20px !important;
  }

  .metric-panel {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
  }

  .row-placement-title {
    align-items: center;
  }

  .arrow-center {
    margin-bottom: 20px !important;
    margin-left: 90px !important;
  }
}