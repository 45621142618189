body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  overscroll-behavior: none;
}

.App nav ~ div.row {
  /* display: flex; */
  margin-right: auto;
}

.centerColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
}

.centerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  flex-direction: row;
  height: 100%;
}

.columnFlex {
  display: flex;

  flex-direction: column;
  height: 100%;
}

.rowFlex {
  display: flex;

  flex-direction: row;
  height: 100%;
}

.subs {
  align-items: center;
  display: flex;
  margin-left: 50px;
}

.displayFlex {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1050px !important;
  }
}

* {
  -webkit-overflow-scrolling: touch;
}

.pagination .page-item.active .page-link,
.page-item.active:hover .page-link:hover {
  background-color: #d81b60;
}

.app-pink {
  color: #d81b60;
}

.app-dark {
  color: #373b44;
}

html,
body,
#root,
.App {
  height: 100%;
}

table.table thead th {
  font-weight: bold;
}

.form-control {
  font-size: 15px;
}

.rdw-editor-wrapper {
  border: 1px solid grey;
}

.custom-select {
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  background-color: transparent;
}

.form-row {
  padding: 15px;
  background-color: white;
}

.form-item-desc {
  font-size: 12;
  font-style: italic;
}

.md-form input[type="text"]:focus:not([readonly]),
.md-form input[type="password"]:focus:not([readonly]),
.md-form input[type="email"]:focus:not([readonly]),
.md-form input[type="url"]:focus:not([readonly]),
.md-form input[type="time"]:focus:not([readonly]),
.md-form input[type="date"]:focus:not([readonly]),
.md-form input[type="datetime-local"]:focus:not([readonly]),
.md-form input[type="tel"]:focus:not([readonly]),
.md-form input[type="number"]:focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form input[type="search"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]),
input:focus,
.active-custom-nav {
  outline: white;
  box-shadow: 0 1px 0 0 rgb(220, 32, 119);
  border-bottom: 1px solid rgb(220, 32, 119);
}

nav {
  margin-bottom: 0px;
}

.breadcrumb {
  background-color: white;
  color: black;
  font-weight: 500;
  margin-top: 55px;
}

.custom-nav {
  color: black;
  font-weight: 500;
}

.custom-nav a {
  line-height: 40px;
  text-align: center;
  color: black;
}

.side-nav .collapsible a.active,
.side-nav .collapsible a:hover {
  font-weight: bold;
}

.collapsible-body a.active,
.collapsible-body a:hover {
  border-left: rgb(220, 32, 119) 2px solid !important;
  font-weight: bold;
}

.table-container {
  padding: 5px;
}

table {
  background-color: white;
}

table th {
  font-weight: 500;
}

table.table-sm th {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

table.table-sm td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  word-break: break-word;
}

.table-container {
  padding: 10px;
  background-color: white;
}

.table-container [type="checkbox"]:not(:checked),
.table-container [type="checkbox"]:checked {
  margin-top: 5px;
}

.nameColumn-email {
  font-size: 10px;
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.main-content {
  margin-top: 60px;
  padding-bottom: 50px;
}

.component-table .table-container {
}

.component-table table thead tr th:nth-child(1) {
  width: 30px;
}

.component-table table thead tr th:nth-child(2) {
  width: 200px;
}

.component-table table thead tr th:nth-child(3) {
  width: 100px;
}

.component-table table thead tr th:nth-child(4) {
  width: 80px;
}

.component-table table thead tr th:nth-child(5) {
  width: 30px;
}

.component-table table thead tr th:nth-child(6),
.component-table table thead tr th:nth-child(7) {
  width: 80px;
}

.component-table table thead tr th:nth-child(8) {
  width: 120px;
}

.component-table table thead tr th:nth-child(9) {
  width: 60px;
}

.account-sub-title {
  margin-top: 56px;
  height: 45px;
  background-color: white;
  padding-top: 10px;
}

.calendar-event-detail {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: white;
  background-color: #414141;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 200ms;
}

.calendar-event-detail:hover {
  padding: 4px 12px;
  transition: all 200ms;
}

.calendar-icon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.calendar-spaces {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

.calendar-item {
  transition: all 200ms;
}

.calendar-item:hover {
  opacity: 0.7;
  cursor: pointer;
  transition: all 200ms;
}

.fc-event {
  background-color: white !important;
  border: 1px solid white !important;
}

#svg-container div[role="toolbar"] button:last-of-type,
div[role="navigation"] {
  display: none !important;
}

.cancel-button {
  background-color: #373b44 !important;
  color: white;
}

.cancel-button:hover,
.cancel-button:active,
.cancel-button:focus {
  color: #ffffff;
  background-color: #515764;
}
.draft-button {
  background-color: #989898 !important;
  color: white;
}

.draft-button:hover,
.draft-button:active,
.draft-button:focus {
  color: #ffffff;
  background-color: #989898;
}

button.btn.pink:hover,
button.btn.pink:active,
button.btn.pink:focus {
  background-color: #a11448 !important;
  transition: all 250ms;
}

button.btn.dark:hover,
button.btn.dark:active,
button.btn.dark:focus {
  background-color: #2a2b2c !important;
  transition: all 250ms;
}

button.btnDark {
  background-color: #2a2b2c !important;
}

.preview-container {
  margin: auto;
}

.preview-container img {
  width: auto;
}

.light-grey {
  background-color: #f8f8f8;
}

.modal-inner {
  padding: 15px;
}

.modal-full-screen .modal-dialog {
  width: 100% !important;
  margin: 0 !important;
  padding: 0;
  min-width: -webkit-fill-available;
}

.modal-full-screen .modal-body {
  margin: 0;
  padding: 0;
}

.row-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.metric-panel-guest {
  margin: 0.375rem 0.375rem 0.375rem 0;
  color: black;
  font-size: 13px;
  text-align: right;
  border-radius: 5px;
  padding: 8px 8px 3px 8px;
  width: 120px !important;
  /* display: inline-block; */
}
.metric-panel-participant {
  margin: 0.375rem;
  color: black;
  font-size: 13px;
  text-align: right;
  border-radius: 5px;
  padding: 8px 8px 3px 8px;
  width: 160px !important;
  /* display: inline-block; */
}

.stats-panel {
  margin: 0.475rem;
  color: black;
  font-size: 13px;
  text-align: right;
  border-radius: 5px;
  padding: 8px;
  /* display: inline-block; */
}

#_composants {
  width: 120px;
}

#_invite_total {
  width: 140px;
}

#_participant_total {
  width: 180px;
}

#_stats_total {
  width: 230px;
}

.sortable-title {
  padding-right: 10px !important;
}

.sortable-title-label {
  /* width: 85%; */
  float: left;
  min-height: 30px;
  padding-left: 15px;
  padding-right: 5px;
}

.sortable-title-arrow {
  width: 10px;
  float: right;
  position: relative;
  /*min-height:30px;
  */
  /*padding-right: 5px;
  */
  display: none;
}

.sortable-title:hover .sortable-title-arrow {
  display: block;
}

.pointer-asc {
  position: absolute;
  bottom: 20px;
}

.pointer-desc {
  position: absolute;
  bottom: 0;
}

.pointer-asc,
.pointer-desc {
  opacity: 1;
  transition: all 200ms;
}

.pointer-asc:hover,
.pointer-desc:hover {
  opacity: 0.6;
  transition: all 200ms;
}

.metric-panel-title {
  font-weight: bold;
  font-size: 11px;
  color: #999999;
  text-align: right;
  padding: 1px;
}

.metric-panel-value {
  font-weight: bold;
  font-size: 28px;
  color: #222428;
  text-align: right;
  padding-right: 0px;
}

.stats-panel-title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.stats-panel-label {
  font-weight: bold;
  font-size: 12px;
  color: #999999;
  text-align: left;
}

.stats-panel-value {
  font-weight: bold;
  font-size: 28px;
  color: #222428;
  text-align: right;
}

.metric-panel-label {
  font-weight: bold;
  font-size: 20px;
  color: #222428;
  text-align: center;
  background-color: white;
  padding: 10px;
}
.metric-panel {
  margin: 0.375rem 0.375rem 0.375rem 0;
  color: black;
  font-size: 13px;
  text-align: right;
  border-radius: 5px;
  padding: 8px;
  /* display: inline-block; */
}

.metric-panel span {
  /*font-size: 30px;*/
}

.campaign-panel-title {
  font-weight: bold;
  text-align: left;
}

.campaign-panel-label {
  font-weight: bold;
  font-size: 14px;
  color: #818181;
  text-align: left;
}

.campaign-panel-label-date {
  font-weight: bold;
  font-size: 12px;
  color: #999999;
  text-align: left;
}

.general-actions {
  text-align: right;
}

.general-actions button,
.general-actions a {
  font-weight: bold;
}

.general-actions .button-container {
  max-width: 200px;
  display: inline-block;
}

.filters {
  padding: 15px;
  margin-bottom: 15px;
}

.margin-top-60 {
  margin-top: 60px;
}

.actions-container-fixed {
  position: fixed;
  bottom: 0;
}

.actions-container {
  background-color: #383b44 !important;
  padding: 13px;
  left: 0;
  width: 100%;
  z-index: 1050;
}

.actions-container .custom-select {
  color: white;
  background-color: #232428;
}

.actions-container .custom-select.email-template {
  color: inherit !important;
  background-color: transparent !important;
}

.actions-container .form-group > input,
.actions-container .form-group > label,
.actions-container .form-group > .md-form > input,
.actions-container .form-group > .md-form > label {
  color: white !important;
}

.payment-method-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0px;
  /* gap: 0rem; */
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: auto; */
}

.payment-method-container label {
  margin: 0 !important;
}

.payment-method-container .payment-mode-icons {
  width: auto;
}

.payment-method-container .payment-mode-card {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  margin-right: 3rem;
}

@media (max-width: 425px) {
  .payment-method-container .payment-mode-card {
    margin-right: 0rem;
  }
}

.payment-mode-card .payment-mode-icons img {
  margin: 0px 0.375rem;
}

.payment-method-container .payment-mode-card .form-check {
  padding-left: 0px;
}

.payment-method-container .check-payment-mode-input {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.payment-method-container .check-payment-mode-input .form-check {
  padding-left: 0px;
}

.stripe-field {
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-bottom: 1px solid grey;
}

.stripe-field label {
  font-size: 12px;
}

.stripe-field .is-empty {
  color: #eee !important;
}

.tox-selectfield select {
  display: block !important;
}

.toast {
  color: white !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.toasts-container {
  width: 320px !important;
  border-radius: 0px !important;
}

.toast-success {
  background-color: #07bc0c !important;
}

.toast-error {
  background-color: #ff3547 !important;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.row-checkbox-pj [type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: initial !important;
  opacity: 0;
  pointer-events: none;
}

.template-checkbox:checked {
  position: absolute !important;
}

.checkbox-pj {
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.error-input {
  border-color: red;
}
.container_guest_buttons {
  text-align: end;
  padding-top: 10px;
}
.container-btn-creating-guest {
  display: flex;
  justify-content: flex-end;
}
.container_guest_buttons .button-container {
  max-width: 200px;
  display: inline-block;
}

.container_guest_buttons button {
  font-weight: normal;
}

.iconDisabled {
  opacity: 0.5;
}

.delegataire-date-title {
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

.delegataire-date-text {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.user-formpage-category-input {
  position: relative;
  z-index: 2;
}

.spinner-blue-only {
  border-color: rgb(220, 32, 119) !important;
}
.spinner-blue-only.mat-progress-spinner svg path {
  stroke: rgb(220, 32, 119) !important;
}

/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-input">
 *       <input />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d81b60;
  border-color: #d81b60;
}

#MenuEditor .draggable-item {
  height: 200px !important;
}

#MenuEditor .form-group {
  line-height: 20px;
}
.select-component{
  border-bottom: 1px solid #fff !important;
}

@media (min-width: 800px) {
  .side-nav.fixed {
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
  }
}

.side-nav-eventcontainer {
  text-align: center;
  position: relative;
  padding: 15px;
  color: black;
}

.side-nav-eventname {
  margin: auto;
  color: white;
  margin-top: 10px;

}

.side-nav-eventlogo {
  background-color: white;
  margin: auto;
  padding: 10px;
  width: 80%;

}

.label-template-emails {
  width: 450px;
}

.margin-left {
  margin-left: 10px;
}

.div-margin-left {
  margin-left: 10px;
}
img {
  width: 100%;
}

.draggable-item,
.draggable-item-horizontal {
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.draggable-item {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-bottom: 2px;
  margin-top: 2px;
}

.reponse-container {
  height: 800px;
}

.zone {
  padding: 10px;
  border: 4px #ccc dashed;
  margin-bottom: 5px;
  margin-right: 5px;
}

.column-drag-handle {
  cursor: move;
}

.smooth-dnd-container {
  height: 100%;
}

.components-list {
  height: 300px;
  overflow: scroll;
}
.openModal{
  cursor: pointer;
}

.delegataire-editor-form {
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}

.delegataire-dates-container {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
}

.delegataire-dates {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bold-titles {
  font-size: 1rem;
  font-weight: 500;
}

.delegataire-quota-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 4rem;
  gap: 4rem;
}

.delegataire-quota-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}

.delegataire-quota-section .cmp-quota-title-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.delegataire-quota-row {
  display: flex;
  align-items: center;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  justify-content: space-between;
}

.row-title h4 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0px;
}

.delegataire-quota-row .delete-btn {
}

.quota-rows-container {
  /* display: flex;
  flex-direction: column;
  gap: 1.5rem; */
  margin-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.new-row-section {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.new-row-section button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.new-row-section .row-select-input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.quota-nbr-and-rmv-btn {
  display: flex;
  grid-gap: 0.625rem;
  gap: 0.625rem;
}

.delegataire-table-head .tab-cell {
  white-space: nowrap;
}

.cell-componant-quota {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.cell-componant-quota,
.cell-category {
  margin-bottom: 0.5rem;
}

.cell-componant-quota:last-child,
.cell-category:last-child {
  margin-bottom: 0rem;
}

.cell-delegataire-packages {
  margin: 0px;
}

.delegataire-table-cell-name {
  margin: 0;
}

.delegataire-table-filter {
  display: flex;
  /* gap: 1rem; */
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.del-filter-items {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 1rem;
}

.del-filter-inputs {
  width: 100%;
  max-width: 350px;
}

.sms-shortcodes-select {
  -webkit-appearance: none;
  text-align: end;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
  color: #212529;
  font-weight: bold;
  width: 100%;
  font-family: "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.sms-shortcodes-select:focus-visible {
  outline: none;
}

.step-circle {
  width: 60px;
  height: 60px;
}

.step-text {
  /* color: #d81b60; */
  font-size: small;
}

.indication-text {
  font-size: 13px;
  font-weight: 400;
}

.sms-campaing-paper-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.sms-step-2-segment-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sms-step-2-segment-list label {
  margin-bottom: 0px;
}

.sms-step-2-segment-list label .MuiFormControlLabel-label {
  font-weight: 600;
}

.sms-step-3 .react-datepicker-wrapper {
  margin-top: 1.5rem;
}

.sms-step-3 .react-datepicker-wrapper input:hover {
  background-color: #f4f4f5;
}

.sms-step-3 .react-datepicker-wrapper input {
  padding: 0.125rem 2rem 0.125rem 1rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e4e4e7;
  border-radius: calc(0.5rem - 2px);
}

.sms-step-2-checkbox-action-btn {
  display: none;
}

.sms-step-2-segment-list:hover .sms-step-2-checkbox-action-btn {
  display: flex;
  grid-gap: 0.275rem;
  gap: 0.275rem;
}

.sms-step-2-checkbox-action-btn .remove-btn {
  color: #e01755;
}

.step-list-icon-container {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tarif-sms {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.tarif-sms p {
  text-decoration: none;
  color: #212529;
  font-weight: 500;
  margin: 0;
}

.tarif-sms .money-icon {
  color: #e01755;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.125rem;
}

.number-validity-title {
  text-decoration: underline;
  font-weight: 500;
}

.number-validity-status-value {
  font-weight: 500;
}

.number-validity-status.valid {
  color: green;
}

.number-validity-status.toFormat {
  color: purple;
}

.number-validity-status.invalid {
  color: red;
}

.number-validity-status.undefined {
  color: grey;
}

.segment_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.segment_container {
  min-width: 870px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2rem;
}

.segment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.segment_header .segment_name {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  position: relative;
}

.segment_name h4 {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
}

.segment_name .helperText {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.75em;
  text-align: center;
  position: absolute;
}

.segment_main {
  margin: 2.5rem 0px;
}

.segment_main .main-content {
  /* min-height: 300px; */
  background-color: #e1dfdf;
  border-radius: 0.5rem;
  padding: 1.2rem;
  margin: 0;
  /* display: flex; */
}

.segment_main h4 {
  font-size: 0.92em;
  font-weight: 600;
  margin: 1rem 0px;
}

.segment-row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.segment-row .and-condition-prefix p {
  margin: 0;
  font-weight: bold;
}

.segment-row-container {
  /* width: 100%; */
  margin-left: 0.5rem;
  display: flex;
}

.MuiAutocomplete-popper.MuiPopperUnstyled-root {
  margin-top: 1rem !important;
}

.segment-footer {
  display: flex;
  justify-content: flex-end;
}

.addConditionButton {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.segment-remove-btn {
  color: #e01755;
}

.segment-dialog-close-btn {
  color: #000000;
}

.segment-remove-btn.segment-remove-btn {
  width: 1.75rem;
  height: 1.75rem;
}

.list_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .list_form {
  min-width: 870px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2rem;
} */

.list-main-content {
  background-color: #e1dfdf;
  border-radius: 0.5rem;
  padding: 1.2rem;
  margin: 0 0 1rem 0;
}

.list_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  grid-gap: 2rem;
  gap: 2rem;
}

.list_header .list_name {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  position: relative;
}

.list_name h4 {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
}

.list_name .helperText {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.75em;
  text-align: center;
  position: absolute;
}

.list-dialog-close-btn {
  color: #000000;
}

td.center-table-item,
th.center-table-item {
  text-align: center;
  vertical-align: middle;
}

.nameColumn {
  width: 15%;
}

.modal-file-body {
  margin: 10px;
}

#_space {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  flex-direction: column;
}

.div-space {
  width: 150px;
  height: 75px;
  background-color: #eeeeee;
  border-radius: 5px;
}

.div-button-add-file {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
}

.button-add-file {
  width: 35px;
  height: 35px;
  border-color: transparent;
}

.metric-upload-file {
}

.label-title {
  font-size: 14px;
  font-weight: 500;
}

.label-subtitle {
  font-size: 11px;
  color: #8a8b8d;
}

.metric-upload-file-title {
  font-weight: bold;
  font-size: 11px;
  color: #999999;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
}

.metric-upload-file-label {
  font-weight: bold;
  font-size: 26px;
  color: #222428;
  text-align: left;
  padding-left: 5px;
  padding-top: 10px;
}

.footer-modal-file {
  border-top: 1px solid black !important;
  margin: 20px;
  margin-top: 0px;
}

.div-btn-footer {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.custom-modal-width {
  /* height: 200px; */
}

.delete-modal-btn {
  background-color: #d81b60;
  color: white;
}

.modalUploadFile {
  /* width: 90vw ; 
  max-width: 90vw; */
}

.div-modal-add-file {
  background-color: #222428;
  flex-wrap: nowrap !important;
}

.loaderUploadFile {
  height: 55;
  position: absolute;
  left: 50%;
  top: 85%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loaderUploadFile.guest {
  height: 55;
  position: absolute;
  left: 50%;
  top: 75%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.labelInputFileUpload {
  color: #fff !important;
  font-size: 13px !important;
}

.input-add-modal-file {
  border: 0px;
  border-bottom: 1px solid #fff !important;
}

.input-add-modal-file.form-control {
  color: #fff !important;
  font-size: 13px;
}

.MuiInputLabel-root.labelFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.selectFileUpload::before {
  border-bottom: 1px solid #fff !important;
}

.selectFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.formSelectUpload > div .MuiSelect-icon {
  color: #fff !important;
}

.Mui-focused.labelFileUpload {
  color: #fff !important;
  font-size: 13px;
}

.selectFileUpload.Mui-focused::after {
  outline: white;
  box-shadow: 0 1px 0 0 #fff;
  border-bottom: 1px solid #fff !important;
}

.centerBtn {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.btnAddFile {
  height: 30px;
}

.btnAbortAddFile {
  height: 30px;
}

.grey {
  background-color: #9c9c9c;
}

button.btn.grey:hover,
button.btn.grey:active,
button.btn.grey:focus {
  background-color: #9c9c9c !important;
  transition: all 250ms;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.invoice-modal-dowload-button {
  cursor: pointer;
}

.invoice-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invoice-modal-dowloads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.invoice-modal-dowload-item {
  margin-bottom: 1rem;
}

.container {
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.gabarit1-page-container {
  /* max-width: 100%; */
  padding-inline: 0px;
}

.gabarit1-page-container .row {
  margin-right: 0px;
  margin-left: 0px;
  padding-inline: 0px;
}

.kanguroo-page {
  padding-bottom: 100px;
}

.page-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  width: "100%";
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin: auto;
  }
}

#checkouform .modal-dialog .modal-content {
  border: 0px;
  box-shadow: none;
}

.background {
  background-size: cover;
}

strong {
  font-weight: bold !important;
}

.hidden {
  display: none !important;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5;
}

.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:after {
  background-color: grey !important;
}

.form-check-input[type="radio"]:checked + label:after,
.form-check-input[type="radio"].with-gap:checked + label:before,
.form-check-input[type="radio"].with-gap:checked + label:after,
label.btn input[type="radio"]:checked + label:after,
label.btn input[type="radio"].with-gap:checked + label:before,
label.btn input[type="radio"].with-gap:checked + label:after {
  border: 2px solid grey !important;
}

.form-check-input[type="radio"]:not(:checked) + label,
.form-check-input[type="radio"]:checked + label,
label.btn input[type="radio"]:not(:checked) + label,
label.btn input[type="radio"]:checked + label {
  line-height: 1.4625rem;
  padding-left: 25px !important;
}

.form-check-label {
  height: auto !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label:after,
label.btn input[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid grey !important;
  background-color: grey !important;
  z-index: 0;
}

.form-inline > .form-check {
  margin-top: 0px !important;
}

.form-element {
  margin-bottom: 0px !important;
}

.guests-container {
  background-color: #eeeeee;
}

.table-title {
  color: black;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid grey;
}

.error {
  color: red;
  font-weight: bold;
  display: none;
}

.required .error {
  display: inline;
}

.img-header {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-container .form-group {
  margin-bottom: 5px !important;
}

.page-sections img {
  max-width: 100% !important;
  width: auto;
  margin: auto;
}

.calendar-item img {
  width: 20px;
  height: 20px;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}

.side-container .form-group > .form-group {
  padding-top: 1px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.modal-calendar .modal-header .close {
  margin: -1rem -4rem -1rem auto !important;
  opacity: 1;
  font-size: 35px;
  color: white;
}

.fc-event {
  border: 1px solid white !important;
}

.fc-head,
.fc-day-grid {
  display: none;
}

#calendar-modal-icon {
  position: absolute;
  width: 30px;
  right: 20px;
}

.calendar-day {
  border-bottom: #7a7476 2px solid;
  height: 2rem;
}

.item-calendar-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.haveIcon .item-calendar-title {
  padding-right: 30px;
}

.fc-widget-content {
  border: 0px !important;
}

.fc-axis {
  border: 0px !important;
  background-color: white !important;
}

.fc-axis span {
  font-size: 1rem;
  color: #808080;
}

.fc-divider {
  display: none;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.calendar-content {
  overflow-x: auto;
}

.fc-slats td {
  height: 50px !important;
  font-size: 8px !important;
}

.calendar-content .fc.fc-ltr.fc-unthemed {
  min-width: 330px;
}

.fc-view-container {
  width: auto;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

@media (max-width: 767px) {
  table {
    border-collapse: collapse;
    height: inherit !important;
    overflow-x: auto;
    max-width: 100%;
    margin: auto;
  }

  .calendar-item {
    width: auto;
  }

  .page-sections span img {
    max-width: none !important;
    width: auto;
    margin: auto;
  }

  .page-sections p > img {
    height: auto !important;
  }

  .col-md-12 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .page-sections .row {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.navbar-toggler-button {
  visibility: visible;
}

@media (min-width: 768px) {
  .navbar-toggler-button {
    visibility: hidden;
  }
}

.bold-titles {
    font-size: 1rem;
    font-weight: 500;
  }
#myTab {
  /* margin-left: 150px; */
  margin-top: 20px;
  border-bottom: none !important;
}

.nav-item-active {
  border-bottom: 4px solid #D81A60;
}

.nav-item-active>button {
  color: white !important;
}

.nav-item-active>.nav-link.active {
  color: black !important;
}

.simple {
  border-bottom: none !important;
}

.flex {
  display: flex;
  flex: 1 1
}

.flex-row {
  flex-direction: row;
}

.center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.rowTitle {
  margin-top: 20px
}

.marginTop {
  margin-top: 20px !important;
}

.titlePageImport {
  font-weight: bold !important;
  color: #D81A60
}

.titlePageImport2 {
  font-weight: bold !important;
  color: #003142
}

.noteImport {
  font-style: italic;
  font-size: 10px;
}

.thConfigImportTableHead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  text-align: center;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 70px;
}

.thConfigImportTableSelect {
  text-align: center;
  font-size: 12px !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 70px;
}

.thConfigImportTable {
  text-align: center;
  font-size: 12px !important;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  width: 200px;
  min-height: 100px;
  overflow-wrap: break-word;
}

.labelCell {
  width: 90%;
}

.selectImport {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.selectImport fieldset {
  height: 100%;
  top: auto;
  background-color: inherit;
}

.selectImport legend {
  margin-left: 10em !important;
  height: 0px !important;
  padding: initial !important;
}

.selectImportNumbers {
  border-radius: 12px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  /* color: #fff !important; */
  font-size: 12px !important;
}

.selectImportNumbers fieldset {
  height: 100%;
  top: auto;
  background-color: inherit;
}

.selectImportNumbers legend {
  margin-left: 10em !important;
  height: 0px !important;
  padding: initial !important;
}

#selectImportActif {
  background-color: #D81A60 !important;
  color: #fff !important;
  border-radius: 12px !important;
}

#selectImportEmptyWhite {
  border-radius: 12px !important;
}

.selectItemEmpty {
  font-size: 12px !important;
}

.selectItem {
  font-size: 12px !important;

}

.dragLoader {
  width: 100%;
  color: #D81A60;
}

.dragdropFile {
  background-color: #fff;
  border-color: #CBCBCB !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  justify-content: center !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragdropFile label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragdropFileError {
  background-color: #fff;
  border-color: red !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  justify-content: center !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragdropFileError label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragDropFileLoader {
  background-color: #fff;
  border-color: #CBCBCB !important;
  border-radius: 15px !important;
  border-width: 2px !important;
  border-style: solid !important;
  height: 160px !important;
  padding: 8px 0px 8px 0px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
  width: 100% !important;
  cursor: pointer !important;
}

.dragDropFileLoader label {
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dragDropLabelEmpty {
  font-size: 13px;
}

.moveDiv {
  z-index: 1;
}

.pushRight {
  margin-left: auto;
}

.moveComponentLeft {
  position: relative;
}

.moveComponentRight {
  position: relative;
}

.moveButton {
  width: 120px !important;
  color: #ffff !important;
  background-color: #D81A60 !important;
  margin: 10px !important;
  padding: 20px !important;
  height: 40px !important;
  border-radius: 20px !important;
  cursor: pointer !important;
}
nav {
  margin-bottom: 20px;
}

.nav-item {
  padding-top: 6px;
  margin: 0 4rem;
  border-bottom: 4px solid black;
}

.item-active {
  border-bottom: 4px solid #d81b60;
}

.containerLogoKanguroo {
  width: 4.375rem;
  height: 3.125rem;
  position: fixed;
  left: 0.625rem;
  top: 0.625rem;
  margin-left: 0.3rem;
}

.headerLogoKanguroo {
  max-height: 2.5rem;
  width: auto;
}


a.link-resetpassword {
  font-size: 12px;
}
a.link-resetpassword:hover,
a.link-resetpassword:focus,
a.link-resetpassword:active {
  color: #d81b60 !important;
}

.forgot-pwd-link-container {
  margin-bottom: 1rem;
}

.forgot-pwd-link {
  color: #c21b2f;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.forgot-pwd-link:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #d81b60;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.forgot-pwd-link:hover:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

input.form-control.validate.logout-pwd-field {
  margin-bottom: 1rem;
}

nav {
  margin-bottom: 20px;
}

nav {
  margin-bottom: 20px;
}

.event-header {
  background-color: #232428;
  padding: 7px;
  color: white;
}

.delegataire-event-name {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.delegataire-event-dates {
  font-size: 13px;
  font-weight: normal;
}

.delegataire-contact {
  padding-top: 15px;
}

.emailPreview h2 {
  font-size: 16px;
  font-weight: bold;
}

.emailPreview b {
  font-weight: bold;
}

.emailPreview-container {
  max-width: 600px;
  margin: auto;
}

.emailPreviewModal .modal-lg {
  max-width: 640px !important;
}

.metric-panel {
  min-width: 180px;
}

.stats-and-actions-item.col-2 {
  padding-left: 0px;
}

.stats-and-actions-item .metrics.row {
  margin-left: 0px;
  margin-right: 0px;
}

.my-packages {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1;
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}

.button-right {
  justify-content: right;
  display: flex;
  flex: 1 1;
}

.modal-delegataire > .modal {
  overflow-y: auto;
}

.blue-background {
  background-color: rgb(179, 233, 233); 
}

.yellow-background {
  background-color: rgb(255, 247, 154); 
}



.quota-state {
  color: #ffffff;
}

.quota-state-empty {
  background-color: #f79f33;
}

.quota-state-full {
  background-color: #b3e9e9;
}

.quota-state-partial {
  background-color: #e6e612;
}

.general-actions {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
}

.checkin-app {
  /*  height: 100%; */
}

.checkin-app .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.checkin-app .app-container {
  margin-top: 50px;
  /* height: 100%; */
}

.checkin-app .app-container-mobile {
  /*  height: 100%; */
}

.checkin-app .statistics {
  background-color: white;
  border-radius: 20px;
  margin-top: 0px;
}

.checkin-app .modal-dialog {
  /*
  margin-top: 100px; 
  top: 50%;
  margin-top: -50% !important;
  */
}

.checkin-app .modal-full-screen .modal-dialog {
  top: 0 !important;
  margin-top: 0 !important;
}

.checkin-app .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.checkin-filter {
  width: 100%;
  position: fixed;
  top: 50px;
  background-color: #eeeeee;
  z-index: 2;
}

.checkin-filter .md-form {
  margin-top: 9px;
}

.checkin-filter .md-form label.active {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.checkin-filter .input-search {
  padding-bottom: 4px;
}

.checkin-list {
  background-color: white;
  margin-top: 150px;
  padding-bottom: 68px;
}

.checkin-list .checkin-list-item {
  padding-top: 9px;
  padding-bottom: 9px;
  border-bottom: 1px solid grey;
  line-height: 15px;
}

.checkin-list-item-guestcompany {
  text-transform: uppercase;
  font-size: 14px;
}

.checkin-list-item-guestname {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.checkin-list-item-guestfirstname {
  text-transform: uppercase;
}

.checkin-list-item-guestfirstname {
  text-transform: capitalize;
}

.checkin-list-item-checkindata {
  text-align: right;
}

.button-addguest {
  border-radius: 0;
}

.checkin-app button.purple-gradient {
  background: rgb(214, 43, 90) !important;
  background: linear-gradient(90deg, rgba(214, 43, 90, 1) 0%, rgba(143, 42, 86, 1) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d62b5a", endColorstr="#8f2a56", GradientType=1) !important;
  font-weight: bold;
}

.checkin-app button.btn-bg-gradient-negatif {
  background: #fff !important;
  background-color: #fff !important;
  border: solid 1px rgba(214, 43, 90, 1) !important;
  color: rgba(214, 43, 90, 1);
  font-weight: bold;
}

.checkin-app button.btn-bg-darkgrey {
  background: #454545 !important;
  background-color: #454545 !important;
  margin-top: 50px;
}

.modal-full-screen .modal-content {
  background-color: transparent;
}

.form-checkin-add-guest .md-form {
  margin-bottom: 0;
}

.img-logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.row-plan {
  flex-wrap: inherit !important;
}

.col-child {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
}

.col-child>.col-md-3 {
  max-width: none !important;
}

.arrow-center {
  margin-left: 50px;
  margin-top: 12px;
}

.placement-guest-infos {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.placement-map svg {
  width: 100%;
  height: calc(100vh - 270px);
}

@media(min-width: 641px) {
  .col-child {
    margin-left: 18px;
  }
}

@media (max-width: 640px) {

  .form-group {
    min-width: 210px;
    margin-top: 10px !important;
  }

  .row-plan {
    display: inherit;
    flex-wrap: inherit;
  }

  .col-child {
    display: flex;
    flex: 1 1;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .child {
    min-width: inherit !important;
  }

  .font-weight-bold {
    font-size: 20px !important;
  }

  .metric-panel {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
  }

  .row-placement-title {
    align-items: center;
  }

  .arrow-center {
    margin-bottom: 20px !important;
    margin-left: 90px !important;
  }
}
.statistics{
  margin-bottom: 15px;
}
.statistics .modal-full-screen .modal-backdrop,
.qrcode-container,
.bg-guestinfo {
  opacity: 1 !important;
  background-color: #d81b60;
  background-image: url("/bg-checkin.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.statistics .modal-full-screen .modal-dialog .modal-content {
  box-shadow: none;
}
.checkin-app > div > div.qrcode-container > section > section > div {
  box-shadow : rgba(1214,43,90,0.6) 0px 0px 0px 5px inset !important;
}

.spinner-black-only {
  border-color: rgb(255, 255, 255) !important;
}
.spinner-black-only.mat-progress-spinner svg path {
  stroke: rgb(255, 255, 255) !important;
}

