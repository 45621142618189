.statistics{
  margin-bottom: 15px;
}
.statistics .modal-full-screen .modal-backdrop,
.qrcode-container,
.bg-guestinfo {
  opacity: 1 !important;
  background-color: #d81b60;
  background-image: url("/bg-checkin.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.statistics .modal-full-screen .modal-dialog .modal-content {
  box-shadow: none;
}
.checkin-app > div > div.qrcode-container > section > section > div {
  box-shadow : rgba(1214,43,90,0.6) 0px 0px 0px 5px inset !important;
}
