.sms-shortcodes-select {
  -webkit-appearance: none;
  text-align: end;
  box-shadow: none !important;
  border: none;
  background-color: transparent;
  color: #212529;
  font-weight: bold;
  width: 100%;
  font-family: "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

.sms-shortcodes-select:focus-visible {
  outline: none;
}

.step-circle {
  width: 60px;
  height: 60px;
}

.step-text {
  /* color: #d81b60; */
  font-size: small;
}

.indication-text {
  font-size: 13px;
  font-weight: 400;
}

.sms-campaing-paper-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.sms-step-2-segment-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sms-step-2-segment-list label {
  margin-bottom: 0px;
}

.sms-step-2-segment-list label .MuiFormControlLabel-label {
  font-weight: 600;
}

.sms-step-3 .react-datepicker-wrapper {
  margin-top: 1.5rem;
}

.sms-step-3 .react-datepicker-wrapper input:hover {
  background-color: #f4f4f5;
}

.sms-step-3 .react-datepicker-wrapper input {
  padding: 0.125rem 2rem 0.125rem 1rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e4e4e7;
  border-radius: calc(0.5rem - 2px);
}

.sms-step-2-checkbox-action-btn {
  display: none;
}

.sms-step-2-segment-list:hover .sms-step-2-checkbox-action-btn {
  display: flex;
  gap: 0.275rem;
}

.sms-step-2-checkbox-action-btn .remove-btn {
  color: #e01755;
}

.step-list-icon-container {
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tarif-sms {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.tarif-sms p {
  text-decoration: none;
  color: #212529;
  font-weight: 500;
  margin: 0;
}

.tarif-sms .money-icon {
  color: #e01755;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.125rem;
}

.number-validity-title {
  text-decoration: underline;
  font-weight: 500;
}

.number-validity-status-value {
  font-weight: 500;
}

.number-validity-status.valid {
  color: green;
}

.number-validity-status.toFormat {
  color: purple;
}

.number-validity-status.invalid {
  color: red;
}

.number-validity-status.undefined {
  color: grey;
}
