.segment_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.segment_container {
  min-width: 870px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2rem;
}

.segment_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
  gap: 2rem;
}

.segment_header .segment_name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
}

.segment_name h4 {
  font-size: 1.1em;
  font-weight: 600;
  margin: 0px;
  white-space: nowrap;
}

.segment_name .helperText {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  font-size: 0.75em;
  text-align: center;
  position: absolute;
}

.segment_main {
  margin: 2.5rem 0px;
}

.segment_main .main-content {
  /* min-height: 300px; */
  background-color: #e1dfdf;
  border-radius: 0.5rem;
  padding: 1.2rem;
  margin: 0;
  /* display: flex; */
}

.segment_main h4 {
  font-size: 0.92em;
  font-weight: 600;
  margin: 1rem 0px;
}

.segment-row {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.segment-row .and-condition-prefix p {
  margin: 0;
  font-weight: bold;
}

.segment-row-container {
  /* width: 100%; */
  margin-left: 0.5rem;
  display: flex;
}

.MuiAutocomplete-popper.MuiPopperUnstyled-root {
  margin-top: 1rem !important;
}

.segment-footer {
  display: flex;
  justify-content: flex-end;
}

.addConditionButton {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.segment-remove-btn {
  color: #e01755;
}

.segment-dialog-close-btn {
  color: #000000;
}

.segment-remove-btn.segment-remove-btn {
  width: 1.75rem;
  height: 1.75rem;
}
